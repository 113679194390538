import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"

const BoardSec = () => {
  return (
    <div css={container}>
      <div css={innerContainer}>
        <div css={textContainer}>
          <h3>一般社団法人MASHING UP 理事</h3>
        </div>
        <div css={styledGrid}>
          <dl css={styledColumn}>
            <dd css={profileImg}>
              <StaticImage src="../images/person01.png" alt="profile" css={personImg} />
            </dd>
            <dt css={profName}>小木曽 麻里</dt>
            <dd css={profileTitle}>SDGインパクトジャパン 代表取締役</dd>
          </dl>
          <dl css={styledColumn}>
            <dd css={profileImg}>
              <StaticImage src="../images/person02.png" alt="profile" css={personImg} />
            </dd>
            <dt css={profName}>夫馬 賢治</dt>
            <dd css={profileTitle}>ニューラル CEO</dd>
          </dl>
          <dl css={styledColumn}>
            <dd css={profileImg}>
              <StaticImage src="../images/person03.png" alt="profile" css={personImg} />
            </dd>
            <dt css={profName}>遠藤 祐子</dt>
            <dd css={profileTitle}>
              メディアジーン MASHING UP編集長
              <br />
              メディアジーン執行役員
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}
export default BoardSec

const container = css`
  background: #8c99ad;
  color: white;
`
const innerContainer = css`
  width: clamp(748px, 80%, 2000px);
  margin: 0 auto;
  padding: 90px 0;

  @media (max-width: 767px) {
    width: 94%;
    padding: 40px 0 10px;
  }
`
const textContainer = css`
  margin-bottom: 120px;

  @media (max-width: 767px) {
    margin-bottom: 60px;
  }

  h3 {
    font-size: 30px;
    position: relative;

    @media (max-width: 767px) {
      font-size: 19px;
      margin-bottom: 0;
      line-height: 1.4;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 1px;
      background: white;
      position: absolute;
      bottom: -20px;
      left: 0;

      @media (max-width: 767px) {
        bottom: -10px;
      }
    }
  }
`
const styledGrid = css`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: block;
  }
`
const styledColumn = css`
  width: 30%;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0 auto 32px;
  }
`
const profileImg = css`
  width: 68%;
  margin: 0 auto 30px;
  @media (max-width: 767px) {
    width: 42%;
    margin: 0 auto 20px;
  }
`
const personImg = css`
  /* border-radius: 50%; */
`
const profName = css`
  font-size: 22px;
  position: relative;
  margin-bottom: 20px;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 15px;
    margin-bottom: 16px;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 1px;
    background: white;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);

    @media (max-width: 767px) {
      bottom: -6px;
    }
  }
`
const profileTitle = css`
  font-size: 15px;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 11px;
  }
`
