import React, { useEffect, useRef } from "react"
import { innerContainer, styledHeadline, rightContainer, contactForm } from "../styles/common.js"

const ContactSec = () => {
  const ref = useRef(null)

  useEffect(() => {
    if(!ref.current || typeof window == 'undefined') return
    // eslint-disable-next-line no-undef
    postscribe(ref.current, `<script type="text/javascript" src="https://mediagene.formstack.com/forms/js.php/mu_org"></script><noscript><a href="https://mediagene.formstack.com/forms/mu_org" title="Online Form">Online Form - MU社団法人用</a></noscript><div style="text-align:right; font-size:x-small;"><a href="http://www.formstack.com?utm_source=jsembed&utm_medium=product&utm_campaign=product+branding&fa=h,4980290" title="Powered by Formstack">Powered by Formstack</a></div>`)
  }, [ref])

  return (
    <div id="contact">
      <div css={innerContainer}>
        <h3 css={styledHeadline}>お問い合わせ</h3>
        <div css={rightContainer}>
          <div ref={ref} css={contactForm} />
        </div>
      </div>
    </div>
  )
}
export default ContactSec
