import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { styledHeadline, rightContainer } from "../styles/common.js";
import linkArrow from "../images/linkArrow.svg";

const NewsSec = ({ news }) => {
  return (
    <div id="news">
      <div css={container}>
        <h3 css={styledHeadline}>What's New</h3>
        <div css={rightContainer}>
          <ul css={newsUl}>
            {news.map(({ node }) => (
              <li key={node.id}>
                <time dateTime={node.date}>{node.date}</time>
                <a href={node.link}>
                  <p>{node.title}</p>
                </a>
              </li>
            ))}
            <li className="more">
              <Link to="/all-news">
                <p>一覧を見る</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default NewsSec;

const newsUl = css`
  li {
    border-top: 1px solid rgba(157, 167, 181, 0.5);
    padding: 20px 0;
    display: flex;
    position: relative;

    time {
      font-weight: normal;
      width: 130px;
      flex: none;
    }

    a {
      padding-right: 10px;
      display: block;
    }

    p {
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-left: 7px;
        background-image: url(${linkArrow});
        background-size: 10px 10px;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @media (max-width: 767px) {
      display: block;
      padding: 12px 0;

      time {
        ont-size: 14px;
      }

      p {
        line-height: 1.5;
      }
    }
  }

  .more {
    text-align: right;
    flex-direction: row-reverse;

    p {
      padding-right: 1.5rem;
    }
  }
`;

const container = css`
  width: clamp(748px, 80%, 2000px);
  margin: 0 auto;
  padding: 80px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 767px) {
    width: 94%;
    display: block;
    padding: 25px 0 0px;
  }
`;
