import React from "react";
import { css } from "@emotion/react";

const AboutSec = ({ logos }) => {
  return (
    <div css={container} id="about">
      <div css={textContainer}>
        <h2>賛助会員</h2>
        <div dangerouslySetInnerHTML={{ __html: logos.content }} />
      </div>
    </div>
  );
};
export default AboutSec;

const container = css`
  width: clamp(748px, 80%, 2000px);
  margin: 0 auto;
  padding: 70px 0 40px;

  @media (max-width: 767px) {
    padding: 54px 0 46px;
  }

  @media (max-width: 767px) {
    width: 94%;
    padding-bottom: 0;
  }
`;
const textContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 767px) {
    display: block;
  }

  h2 {
    width: 40%;
    font-size: 30px;
    position: relative;
    margin-bottom: 82px;

    @media (max-width: 767px) {
      width: 100%;
      font-size: 19px;
      margin-bottom: 60px;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 1px;
      background: #003c90;
      position: absolute;
      bottom: -20px;
      left: 0;

      @media (max-width: 767px) {
        bottom: -10px;
      }
    }
  }

  div {
    width: 100%;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 40px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
    }
  }

  li {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
    a {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  aside {
    margin-top: 7px;
    font-size: 13px;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }
`;
