import React from "react"
import { css } from "@emotion/react"
import icon01 from "../images/aboutIcon01.svg"
import icon02 from "../images/aboutIcon02.svg"
import icon03 from "../images/aboutIcon03.svg"
import icon04 from "../images/aboutIcon04.svg"
import iconLink from "../images/aboutIconBlank.svg"
import aboutArrow from "../images/aboutArrow.svg"
import aboutArrowSp from "../images/aboutArrowSp.svg"
import aboutCircle from "../images/aboutCircle.svg"
import aboutCross from "../images/aboutCross.svg"

const AboutSec = () => {
  return (
    <div css={container} id="about">
      <div css={textContainer}>
        <h2>事業について</h2>
        <p>
          DE&amp;Iをはじめ、ESG経営に必要なラーニングコンテンツや学びの機会を提供。
          <br />
          イベント開催などを通じて共に学ぶコミュニティを形成し、情報発信を行うことで社会全体の持続的な発展に貢献します。
        </p>
      </div>
      <div css={styledGrid}>
        <div css={styledColumn}>
          <div>
            <img src={icon01} alt="about icon" />
            <p css={columnP}>
              企業の社員教育のための
              <br />
              ラーニングコンテンツの提供
            </p>
          </div>
        </div>
        <div css={styledColumn}>
          <div>
            <img src={icon02} alt="about icon" />
            <p css={columnP}>
              メディア運営による
              <br />
              情報発信
            </p>
            <div css={columnLink}>
              <a href="https://www.mashingup.jp" rel="noreferrer" target="_blank">MASHING UP<img src={iconLink} width="10" height="10" alt="新しいタブで開く" /></a>
            </div>
          </div>
        </div>
        <div css={styledColumn}>
          <div>
            <img src={icon03} alt="about icon" />
            <p css={columnP}>
              企業間の研修会および講習会
              <br />
              （イベントやコミュニティー形成）
              <br />
              の企画・運営
            </p>
          </div>
        </div>
      </div>
      <div css={bottomSec}>
        <img src={icon04} alt="about icon" />
        <p css={columnP}>
          企業内個人の意識向上
          <br />
          および知識の習得を推進し
          <br />
          企業価値の向上に貢献
        </p>
      </div>
    </div>
  )
}
export default AboutSec

const container = css`
  width: clamp(748px, 80%, 2000px);
  margin: 0 auto;
  padding: 90px 0;

  @media (max-width: 767px) {
    padding: 54px 0 46px;
  }

  @media (max-width: 767px) {
    width: 94%;
  }
`
const textContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 80px;

  @media (max-width: 767px) {
    display: block;
    margin-bottom: 40px;
  }

  h2 {
    width: 40%;
    font-size: 30px;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
      font-size: 19px;
      margin-bottom: 24px;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 1px;
      background: #003c90;
      position: absolute;
      bottom: -20px;
      left: 0;

      @media (max-width: 767px) {
        bottom: -10px;
      }
    }
  }

  p {
    width: 60%;
    font-size: 19px;

    @media (max-width: 767px) {
      width: 100%;
      font-size: 14px;
    }
  }
`
const styledGrid = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5vw;
  align-items: center;
  margin-bottom: 12vw;
  position: relative;

  @media (max-width: 1300px) {
    gap: 2vw;
  }

  &::after {
    content: "";
    background-image: url(${aboutArrow});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 72%;
    min-height: 8vw;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate3d(-50%, 100%, 0);

    @media (max-width: 767px) {
      background-image: url(${aboutArrowSp});
      width: 26px;
      height: 26px;
      min-height: auto;
    }
  }

  @media (max-width: 767px) {
    display: block;
  }
`
const styledColumn = css`
  text-align: center;
  background-image: url(${aboutCircle});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 5%;
  width: 100%;
  min-height: 23vw;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    min-height: 40vw;
    position: relative;
    padding: 0;

    &:not(:last-of-type)::after {
      content: "";
      background-image: url(${aboutCross});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 18px;
      height: 18px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate3d(-50%, 100%, 0);
    }
  }

  @media (max-width: 600px) {
    min-height: 50vw;
  }
  @media (max-width: 500px) {
    min-height: 70vw;
  }

  img {
    margin: 0 auto 30px;

    @media (max-width: 767px) {
      width: 22vw;
      margin: 0 auto 10px;
    }
  }
`
const bottomSec = css`
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 70px;
  }

  img {
    margin: 0 auto 30px;

    @media (max-width: 767px) {
      width: 22vw;
      margin: 0 auto 10px;
    }
  }
`
const columnP = css`
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  font-size: 19px;
  line-height: 1.7;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 14px;
  }
`

const columnLink = css`
  text-align: center;
  width: 100%;
  margin-top: 20px;

  a {
    color: #003C90;
    font-size: 16px;
    line-height: 1.7;
    background: #F0F2F4;
    padding: 10px 25px;
    border-radius: 19px;
  }

  img {
    width: 10px;
    height: 10px;
    margin: 0 0 2px 7px;
  }

  @media (max-width: 767px) {
    width: 100%;
    bottom: inherit;
    position: relative;
    margin-top: 5px;
    a {
      font-size: 13px;
      padding: 8px 17px;
    }
    img {
      width: 8px;
      height: 8px;
      margin: 0 0 1px 7px;
    }
  }
`