import React from "react"
import { css } from "@emotion/react"
import { innerContainer, styledHeadline, rightContainer } from "../styles/common.js"

const OutlineSec = () => {
  return (
    <div id="outline">
      <div css={innerContainer}>
        <h3 css={styledHeadline}>概要</h3>
        <div css={rightContainer}>
          <div css={styledRow}>
            <h4>名称</h4>
            <p css={rightColumn}>一般社団法人 MASHING UP</p>
          </div>
          <div css={styledRow}>
            <h4>所在地</h4>
            <p css={rightColumn}>
              〒150-0044
              <br />
              東京都渋谷区円山町23番2号アレトゥーサ渋谷3Ｆ
            </p>
          </div>
          <div css={styledRow}>
            <h4>設立年月</h4>
            <p css={rightColumn}>2022年3月1日</p>
          </div>
          <div css={styledRow}>
            <h4>設立目的</h4>
            <p css={rightColumn}>日本企業のダイバーシティー推進、社会的事業の発展に寄与することを目的とする。</p>
          </div>
          <div css={styledRow}>
            <h4>事業内容</h4>
            <div css={rightColumn}>
              <p>日本企業のダイバーシティーに関する知識、技能の普及および啓蒙に関する事業</p>
              <ol>
                <li>１）企業の社員教育のためラーニングコンテンツの提供</li>
                <li>２）企業間の研修会および講習会（イベントやコミュニティー形成）の企画・運営</li>
                <li>３）メディア運営による情報発信</li>
                <li>４）企業内個人の意識向上及び知識の習得を推進し、企業価値の向上に貢献</li>
              </ol>
            </div>
          </div>
          <div css={styledRow}>
            <h4>理事</h4>
            <p css={rightColumn}>
              遠藤祐子（代表）<br />
              夫馬賢治<br />
              小木曽麻里
            </p>
          </div>
          <div css={styledRow}>
            <h4>監事</h4>
            <p css={rightColumn}>
              冨樫恵衣<br />
              寺尾洋之
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OutlineSec

const styledRow = css`
  display: flex;
  margin-bottom: 40px;
  line-height: 2.5;

  @media (max-width: 767px) {
    margin-bottom: 26px;
    line-height: 1.85;
    margin-bottom: 0;
  }

  h4 {
    width: 130px;

    @media (max-width: 767px) {
      width: 90px;
      font-size: 14px;
    }
  }
`
const rightColumn = css`
  width: calc(100% - 130px);
  @media (max-width: 767px) {
    width: calc(100% - 90px);
    font-size: 14px !important;
    & > * {
      font-size: 14px !important;
    }
  }
  ol {
    font-weight: bold;
  }
`
