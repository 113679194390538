import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FvSec from "../components/fvSec";
import AboutSec from "../components/aboutSec";
import SupportersSec from "../components/supportersSec";
import SupportersLogoSec from "../components/supportersLogoSec";
import BoardSec from "../components/boardSec";
import OutlineSec from "../components/outlineSec";
import NewsSec from "../components/newsSec";
import ContactSec from "../components/contactSec";

const HomePage = ({ data }) => {
  const { homepage } = data.siteMetaData;
  const news = data.allNews.edges;

  return (
    <Layout {...homepage}>
      <Seo title="home" />
      <FvSec page="top" />
      <NewsSec news={news} />
      <AboutSec />
      <BoardSec />
      <SupportersSec suporters={data.suporters} />
      <SupportersLogoSec logos={data.logos} />
      <OutlineSec />
      <ContactSec />
    </Layout>
  );
};
export default HomePage;

export const query = graphql`
  {
    siteMetaData: site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allNews: allWpPost(limit: 3, sort: { fields: date, order: DESC }) {
      edges {
        node {
          date(formatString: "YYYY.MM.DD")
          title
          id
          link
        }
      }
    }
    suporters: wpPage(id: { eq: "cG9zdDoxMjY=" }) {
      id
      title
      content
    }
    logos: wpPage(id: { eq: "cG9zdDo5MA==" }) {
      content
    }
  }
`;
